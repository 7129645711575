@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

:root {
	font-family: Montserrat, Inter, Avenir, Helvetica, Arial, sans-serif;
	font-size: 16px;
	line-height: 24px;
	font-weight: 400;

	color-scheme: light dark;
	color: rgba(255, 255, 255, 0.87);
	background-color: #242424;

	font-synthesis: none;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-text-size-adjust: 100%;

	--primary: #646cff;
}

* {
	outline: none;
	box-sizing: border-box;
}

a {
	font-weight: 500;
	color: var(--primary);
	text-decoration: inherit;
}
a:hover {
	color: #535bf2;
}

body {
	margin: 0;
	display: flex;
	justify-content: center;
	min-width: 320px;
	min-height: 100vh;
}

#root {
	width: 100%;
}

h1 {
	font-size: 3.2em;
	line-height: 1.1;
}

button {
	border-radius: 8px;
	border: 1px solid transparent;
	padding: 0.6em 1.2em;
	font-size: 1em;
	font-weight: 500;
	font-family: inherit;
	background-color: #1a1a1a;
	background: #1a1a1a;
	cursor: pointer;
	transition: border-color 0.25s;
	color: white;
}
button:hover {
	border-color: #646cff;
}
button:focus,
button:focus-visible {
	/* outline: 4px auto -webkit-focus-ring-color; */
	outline: none;
}

input {
	border-radius: 8px;
	border: 1px solid transparent;
	padding: 0.6em 1.2em;
	font-size: 1em;
	font-weight: 500;
	font-family: inherit;
	background-color: #1a1a1a;
	background: #1a1a1a;
	transition: border-color 0.25s;
	color: white;
}

input:hover {
	border-color: var(--primary);
}

@media (prefers-color-scheme: light) {
	:root {
		color: #213547;
		background-color: #ffffff;
	}
	a:hover {
		color: #747bff;
	}
	button {
		background-color: #f9f9f9;
	}
}
